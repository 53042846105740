import { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { fetchUserAttributes, updateUserAttributes } from "aws-amplify/auth";
import { Button } from "@aws-amplify/ui-react";

function NumberPopup() {
  const [open, setOpen] = useState(true);
  const [formName, setFormName] = useState();
  const [formNumber, setFormNumber] = useState();
  const [user, setUser] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getUserDetails() {
      try {
        const user = await fetchUserAttributes();

        setUser(user);
      } catch (error) {
        console.error("Error fetching futsals:", error);
      } finally {
        setLoading(false);
      }
    }

    getUserDetails();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await updateUserAttributes({
        userAttributes: {
          name: formName,
          phone_number: `+94${formNumber}`,
        },
      });

      handleClose();
    } catch (error) {
      console.error("Error updating user attributes:", error);
    }
  };

  return (
    <>
        {loading ? (
        <></>
      ) : user.phone_number ? (
        <></>
      ) : (
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            component: "form",
            onSubmit: handleSubmit,
          }}
        >
          <DialogTitle>Edit Profile</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please make sure that the given information is correct.
            </DialogContentText>
            <TextField
              autoFocus
              required
              margin="dense"
              id="name"
              name="name"
              label="Name"
              type="text"
              fullWidth
              variant="standard"
              onChange={(e) => setFormName(e.target.value)}
            />
            <TextField
              autoFocus
              required
              margin="dense"
              id="phoneNumber"
              name="phoneNumber"
              label="Phone Number"
              placeholder="0773456789"
              type="text"
              fullWidth
              variant="standard"
              onChange={(e) => setFormNumber(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Update</Button>
          </DialogActions>
        </Dialog>
      )}
      </> 
  );
}

export default NumberPopup;
