/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_appsync_graphqlEndpoint": "https://wb7yoap27fghrj6ycodhh5f2a4.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-lfnizhrq5nhyzhcp5ncizga22u",
    "aws_cognito_identity_pool_id": "ap-south-1:a5afce90-0d00-4beb-b2cf-f4f4b93e3107",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_zUBNAo6jz",
    "aws_user_pools_web_client_id": "64kjhdjpktscb9unmg354232ou",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "NAME",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "futsalmedias25221-dev",
    "aws_user_files_s3_bucket_region": "ap-south-1"
};


export default awsmobile;
