import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import "./Timeslot.scss";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const TimeSlot = ({ startTime = "9:00 AM", endTime = "1:00 AM", interval = 1, onTimeSelect }) => {
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setScroll(scrollType);
    setOpen(true);
  };

  const handleClose = () => {
    onTimeSelect(selectedTimeSlots);
    setOpen(false);
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const generateTimeSlots = (start, end, interval) => {
    const slots = [];
    let currentTime = moment(start, "h A");
    let endTimeMoment = moment(end, "h A");

    if (endTimeMoment.isBefore(currentTime)) {
      endTimeMoment.add(1, "day");
    }

    while (currentTime < endTimeMoment) {
      const startTimeFormatted = currentTime.format("h A");
      currentTime.add(interval, "hours");
      const endTimeFormatted = currentTime.format("h A");
      if (currentTime.isBefore(endTimeMoment)) {
        slots.push(`${startTimeFormatted} - ${endTimeFormatted}`);
      }
    }

    return slots;
  };

  const timeSlots = generateTimeSlots(startTime, endTime, interval);

  // const handleTimeClick = (slot) => {
  //   let prevSelectedSlot = selectedTimeSlots.length > 0 ? selectedTimeSlots[selectedTimeSlots.length - 1] : null;
  
  //   if (selectedTimeSlots.includes(slot)) {
  //     // Deselect the time slot if it's already selected
  //     setSelectedTimeSlots((prevSlots) => prevSlots.filter((s) => s !== slot));
  //     prevSelectedSlot = null; // Reset prevSelectedSlot on deselect
  //   } else if (
  //     selectedTimeSlots.length < 4 &&
  //     (prevSelectedSlot === null ||
  //       (getSlotIndex(slot) - getSlotIndex(prevSelectedSlot) === 1 ||
  //         getSlotIndex(slot) - getSlotIndex(prevSelectedSlot) === -1))
  //   ) {
  //     // Allow selection if less than 4 slots are selected and slots are adjacent
  //     setSelectedTimeSlots((prevSlots) => [...prevSlots, slot]);
  //     prevSelectedSlot = slot; // Update prevSelectedSlot on selection
  //   }
  // };

  // THIS IS THE OTHER ONE
  const handleTimeClick = (slot) => {
    let prevSelectedSlot = selectedTimeSlots.length > 0 ? selectedTimeSlots[selectedTimeSlots.length - 1] : null;
    let nextSelectedSlot = selectedTimeSlots.length > 1 ? selectedTimeSlots[selectedTimeSlots.length - 2] : null;
  
    if (selectedTimeSlots.includes(slot)) {
      // Deselect logic
      if (
        selectedTimeSlots.length === 1 || 
        (selectedTimeSlots.length > 1 && !selectedTimeSlots.includes(timeSlots[getSlotIndex(slot) + 1])) ||
        (selectedTimeSlots.length > 1 && !selectedTimeSlots.includes(timeSlots[getSlotIndex(slot) - 1])) ||
        (selectedTimeSlots.length > 2 && !selectedTimeSlots.includes(timeSlots[getSlotIndex(slot) + 1]) && !selectedTimeSlots.includes(timeSlots[getSlotIndex(slot) - 1]))
      ) {
        setSelectedTimeSlots((prevSlots) => prevSlots.filter((s) => s !== slot));
        prevSelectedSlot = null; // Reset prevSelectedSlot on deselect
      }
    } else if (
      selectedTimeSlots.length < 4 &&
      (prevSelectedSlot === null ||
        (getSlotIndex(slot) - getSlotIndex(prevSelectedSlot) === 1) || (getSlotIndex(slot) - getSlotIndex(prevSelectedSlot) === -1 && 
        (selectedTimeSlots.includes(timeSlots[getSlotIndex(slot) + 1]) || selectedTimeSlots.includes(timeSlots[getSlotIndex(slot) - 1])) ||
        (getSlotIndex(slot) - getSlotIndex(prevSelectedSlot) === -1 && selectedTimeSlots.includes(timeSlots[getSlotIndex(prevSelectedSlot) - 1]))) 
      ) 
    ) {
      // Allow selection if less than 4 slots are selected and slots are adjacent
      // or if the previous slot is selected and the next slot or previous slot to the previous slot is also selected
      setSelectedTimeSlots((prevSlots) => [...prevSlots, slot]);
      prevSelectedSlot = slot; // Update prevSelectedSlot on selection
    }
  };
  
  const getSlotIndex = (slot) => timeSlots.indexOf(slot);

  const handleConfirmSelection = () => {
    onTimeSelect(selectedTimeSlots); // Pass the selected time slots to the parent
    handleClose();
  };

  const formatTimeSlot = (timeSlot) => {
    const endIndex = timeSlot.length - 1
    if (timeSlot[0]) {
      const startTime = timeSlot[0].split("-")[0].split(" ")[0];
      const startAbbr = timeSlot[0].split("-")[0].split(" ")[1];
      const endTime = timeSlot[endIndex].split("-")[1].split(" ")[1];
      const endAbbr = timeSlot[endIndex].split("-")[1].split(" ")[2];
      return (
        <>
          <span className="bigtext">{startTime}</span><span className="smalltext">{startAbbr}</span><span className="bigtext">- {endTime}</span><span className="smalltext">{endAbbr}</span>
        </>
      );
    } else {
      return <span style={{fontSize:"25px"}}>No time slot selected</span>;
    }
  }

  return (
    <div id="timeSlots" className="time">
      <label>Time:</label>
      <div className="timeInner">
        <div>
          {formatTimeSlot(selectedTimeSlots)}
        </div>
        <button
          value={selectedTimeSlots.join(", ")}
          onClick={handleClickOpen("paper")}
          readOnly
        >SELECT TIMESLOT</button>
      </div>
      
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Available Time Slots</DialogTitle>
        <div className="note">
            <svg width="32" height="33" viewBox="0 0 32 33" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.1886 6.65985C16.4412 6.65985 16.6834 6.76018 16.862 6.93877C17.0405 7.11735 17.1409 7.35957 17.1409 7.61212V19.0394C17.1409 19.292 17.0405 19.5342 16.862 19.7128C16.6834 19.8913 16.4412 19.9917 16.1886 19.9917C15.936 19.9917 15.6938 19.8913 15.5152 19.7128C15.3367 19.5342 15.2363 19.292 15.2363 19.0394V7.61212C15.2363 7.35957 15.3367 7.11735 15.5152 6.93877C15.6938 6.76018 15.936 6.65985 16.1886 6.65985Z"
                fill="black"
              />
              <path
                d="M17.7758 24.4356C17.7758 24.8565 17.6086 25.2602 17.3109 25.5579C17.0133 25.8555 16.6096 26.0227 16.1887 26.0227C15.7678 26.0227 15.3641 25.8555 15.0664 25.5579C14.7688 25.2602 14.6016 24.8565 14.6016 24.4356C14.6016 24.0147 14.7688 23.611 15.0664 23.3133C15.3641 23.0157 15.7678 22.8485 16.1887 22.8485C16.6096 22.8485 17.0133 23.0157 17.3109 23.3133C17.6086 23.611 17.7758 24.0147 17.7758 24.4356Z"
                fill="black"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.8712 31.1015C23.9351 31.1015 30.4727 24.5638 30.4727 16.5C30.4727 8.43615 23.9351 1.89848 15.8712 1.89848C7.80737 1.89848 1.2697 8.43615 1.2697 16.5C1.2697 24.5638 7.80737 31.1015 15.8712 31.1015ZM15.8712 32.3712C24.6372 32.3712 31.7424 25.266 31.7424 16.5C31.7424 7.73401 24.6372 0.628784 15.8712 0.628784C7.10523 0.628784 0 7.73401 0 16.5C0 25.266 7.10523 32.3712 15.8712 32.3712Z"
                fill="black"
              />
            </svg>
            <p>
              <b>Book only continuous slots</b>
              <br />
              if something unintended happens, deselect and select back the slots again
            </p>
          </div>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <div id="timeSlots">
              {timeSlots.map((slot, index) => (
                <div
                  onClick={() => handleTimeClick(slot)}
                  className={`ATimeSlot ${
                    selectedTimeSlots.includes(slot) ? "selected" : ""
                  }`}
                  key={index}
                >
                  {slot}
                </div>
              ))}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          
          <Button onClick={handleConfirmSelection}>
            Confirm Selection
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TimeSlot;
