/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBooking = /* GraphQL */ `
  query GetBooking($bookingId: ID!) {
    getBooking(bookingId: $bookingId) {
      customerId
      name
      phone
      bookingId
      bookedDate
      bookedTimeslot
      amount
      futsalId
      date
      time
      type
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listBookings = /* GraphQL */ `
  query ListBookings(
    $bookingId: ID
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBookings(
      bookingId: $bookingId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        customerId
        name
        phone
        bookingId
        bookedDate
        bookedTimeslot
        amount
        futsalId
        date
        time
        type
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFutsal = /* GraphQL */ `
  query GetFutsal($futsalId: ID!) {
    getFutsal(futsalId: $futsalId) {
      futsalId
      name
      priceHour
      rating
      phone
      telephone
      city
      embedLink
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listFutsals = /* GraphQL */ `
  query ListFutsals(
    $futsalId: ID
    $filter: ModelFutsalFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFutsals(
      futsalId: $futsalId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        futsalId
        name
        priceHour
        rating
        phone
        telephone
        city
        embedLink
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
